import React, { useState } from 'react';
import SplashScreen from './components/SplashScreen';
import SignupPage from './pages/SignupPage';
import './App.css';
import SpeechRecognitionApp from './pages/Recorder';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  function handleLoaded() {
    setIsLoading(false);
  }

  return (
    <div className="App">
      <SpeechRecognitionApp /> 
    </div>
  );
}

export default App;