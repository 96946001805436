
import React from 'react';
import SignupForm from '../components/SignupForm';
import '../App.css';

function SignupPage() {
  function handleSignup(userData) {
    console.log(`User Signed Up: Email - ${userData.email}, Username - ${userData.username}`);
    // Add API call to server for signup
  }

  return (
    <div id="signup-page">
      <h2>Sign up for free</h2>
      <p>Let's sign up quickly to get started</p>
      <SignupForm onSignup={handleSignup} />
      <p>Already have an account? <a href="#" id="sign-in-link">Sign In</a></p>
    </div>
  );
}

export default SignupPage;