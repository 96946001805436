import React, { useState, useEffect } from 'react';

const SpeechRecognitionApp = () => {
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  useEffect(() => {
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = (event) => {
      let finalTranscript = '';
      let interimTranscript = '';
      for (let i = 0; i < event.results.length; i++) {
        const transcriptSegment = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcriptSegment + ' ';
        } else {
          interimTranscript += transcriptSegment;
        }
      }
      setTranscript(finalTranscript + interimTranscript);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleStartListening = () => {
    setTranscript('');
    setIsListening(true);
  };

  const handleStopListening = () => {
    setIsListening(false);
  };

  const handleClear = () => {
    setTranscript('');
    setCopySuccess('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(transcript).then(() => {
      setCopySuccess('Text copied to clipboard!');
      setTimeout(() => setCopySuccess(''), 2000);
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
      <h1>Transcriber App</h1>
      <button
        onClick={handleStartListening}
        disabled={isListening}
        style={{
          margin: '10px',
          padding: '10px',
          backgroundColor: isListening ? '#d3d3d3' : '#4CAF50',
          color: 'white',
          cursor: isListening ? 'not-allowed' : 'pointer',
        }}
      >
        Start Listening
      </button>
      <button
        onClick={handleStopListening}
        disabled={!isListening}
        style={{
          margin: '10px',
          padding: '10px',
          backgroundColor: !isListening ? '#d3d3d3' : '#f44336',
          color: 'white',
          cursor: !isListening ? 'not-allowed' : 'pointer',
        }}
      >
        Stop Listening
      </button>
      <button
        onClick={handleClear}
        disabled={!transcript}
        style={{
          margin: '10px',
          padding: '10px',
          backgroundColor: transcript ? '#ff9800' : '#d3d3d3',
          color: 'white',
          cursor: transcript ? 'pointer' : 'not-allowed',
        }}
      >
        Clear
      </button>
      <textarea
        value={transcript}
        readOnly
        rows="10"
        style={{ width: '100%', marginTop: '20px', padding: '10px' }}
      />
      {transcript && (
        <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={handleCopy}>
          📋 Copy to Clipboard
        </div>
      )}
      {copySuccess && (
        <div style={{ marginTop: '10px', color: 'green' }}>
          {copySuccess}
        </div>
      )}
    </div>
  );
};

export default SpeechRecognitionApp;
